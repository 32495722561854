th{
    position: relative;
    padding-right: 30px;
}
th:after{
    position: absolute;
    left: calc(100% - 25px);
}
#sidebar{
    transition: all .3s linear;
}
.dataTables_paginate{
    display: flex;
}
.app-header{
    border-bottom: 1px solid #e9ecef;
}
.app-header .navbar-user .avatar {
    width: 30px;
    height: 30px;
    margin-top: -5px;
    margin-bottom: -5px;
    border-radius: 30px;
    margin-right: 10px;
    background-color: #008181;
    color: #FFFFFF;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
.navbar-nav .dropdown-menu.show{
    position: absolute;
    inset: 0 0 auto auto;
    transform: translate(0px, 52px);
}
.menu-text{
    cursor: pointer;
}
#content.app-content{
    padding: 0;
}
.profile-content .form-control{
    max-width: 300px;
}
.login .invalid-tooltip{
    top: -30px;
}
.login .valid-tooltip{
    top: -30px;
}
.reset-password .login-body{

}
div.dataTables_wrapper div.dataTables_length select{
    margin-left: 0.5em;
}
.register.register-with-news-feed .register-container{
    margin-left: 0;
    padding-top: 50px;
    justify-content: flex-start;
}
.pagination{
    justify-content: flex-end;
}
