@import "../fonts";

.link-info{
  font-family: $font-r-r;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: right;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 36px;
  a{
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
  }
}
.bottom-info{
  font-family: $font-r-r;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  a{
    font-family: $font-r-b;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
  }
}
