@import "../fonts";
@import "../variables";
@import "../mixins";
.splitter{
  font-family: $font-r-r;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
  color: #49454f;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
  margin: 16px 0;
  @include max-w($media-xs){
    margin: 20px 0;
  }
}
