@import "../fonts";
@import "../variables";
@import "../mixins";
.block-logo{
  max-width: 510px;
  padding: 46px 30px 22px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.account-created{
  .user-verify h2 {
    @include max-w($media-xs){
      padding-top: 0;
    }
  }
  .title-label img {
    @include max-w($media-xs){
      right: 6px;
      top: -193px;
    }
  }
}
