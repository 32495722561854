@import "../fonts";
@import "../variables";
@import "../mixins";
.btn{
  &-grey{
    background-color: rgba(29, 27, 32, 0.12) !important;
    border: none;
    opacity: 0.38;
    color: #1d1b20;
  }
}
.user-verify{
  h2{
    @include max-w($media-xs){
      padding-top: 185px;
    }
  }
  .title-label img {
    @include max-w($media-xs){
      right: -1px;
    }
  }
  span{
    margin-bottom: 8px;
    display: inline-block;
    a{
      color: #49454f;
      font-family: $font-r-b;
      font-weight: bold;
      text-decoration: none;
    }
  }
  .block-button{
    margin-top: 21px;
    .btn-grey{
      color: #1D1B20!important;
    }
  }
  .bottom-info{
    padding-top: 15px;
    &>span{
      margin-bottom: 0;
    }
    .sub-text{
      text-align: center;
      max-width: 267px;
      padding: 25px 0;
      display: inline-block;
      line-height: 1;
      span, b{
        font-size: 12px;
        text-align: center;
        color: #707070;
        margin-bottom: 0;
        display: inline;
      }

    }
  }
  .block-message{
    padding-top: 14px;
    text-align: center;
    font-weight: bold;
  }
}
