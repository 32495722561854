@import "../fonts";

.pin-authorization {
  form {
    padding-top: 14px;
  }

  h5, .label-radio-group {
    font-family: $font-r-m;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: left;
    color: #000;
    margin-bottom: 13px;
  }

  .form-info {
    font-family: $font-r-r;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: left;
    color: #006548;
    padding: 15px 2px;

    svg {
      margin-right: 11px;
    }
  }

  .block-add-component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      font-family: $font-r-m;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 0.1px;
      text-align: left;
      color: #006548;
      text-decoration: none;
      margin-left: 15px;
      margin-top: -10px;
      margin-bottom: 16px;
      &:focus{
        outline: 1px #006548 dashed;
      }
      svg {
        margin-right: 12px;
        transform: translate(0, -2px);
      }
    }
  }
}
.input-radio {
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  margin-bottom: 15px;

  label {
    display: flex;
    position: relative;
    z-index: 0;

    &.label-disabled{
      pointer-events: none;
      opacity: .5;
    }
    input {
      opacity: 0;
      margin-right: 24px;

      & + .icon-radio {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        z-index: 0;
        width: 20px;
        height: 20px;
        background: url("/assets/images/icon-radio.svg") left/contain;
      }

      &:checked {
        & + .icon-radio {
          content: "";
          background: url("/assets/images/icon-radio-checked.svg") left/contain;
        }
      }
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .pin-name {
      font-family: $font-r-r;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: left;
      color: #49454f;
    }

    .device-count {
      font-family: $font-r-r;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #57575C;
    }
  }
}
.block-deactivated-ornament{
  border-top: 1px solid #DED8E1;
  margin-bottom: 27px;
  h6, .label-deactivated-ornament{
    font-family: $font-r-b;
    font-size: 12px;
    color: #605D64;
    margin: 9px 0 3px 0;
  }
  .pin-name{
    font-family: $font-r-r;
    font-size: 12px;
    color: #49454F;
    margin-bottom: 2px;
  }
}
