//mixin
@mixin transition($property: all, $duration: .2s, $timing: linear, $delay: 0s){
  transition: $property $duration $timing $delay;
}
@mixin bold-shadow($blur:0.5, $color:#006e6b){
  text-shadow: 0 0 $blur + px $color,  0 0 $blur + px $color;
  @-moz-document url-prefix(){
    text-shadow: 0 0 0 $color,  0 0 0 $color;
  }
}
// Media Queries Min Width

@mixin min-w($width) {
  @media screen and (min-width: $width + px) { @content; }
}

// Media Queries Max Width

@mixin max-w($width) {
  @media screen and (max-width: $width + px) { @content; }
}

// Media Queries Max Min Width

@mixin max-min-w($max-width, $min-width) {
  @media screen and (max-width: $max-width + px) and (min-width: $min-width + px) { @content; }
}

// Media Queries Max Min Width/Height Landscape
@mixin max-w-ls($max-width){
  @media screen and (max-device-width: $max-width + px) and (orientation: landscape) { @content; }
}
@mixin min-w-ls($min-width){
  @media screen and (min-device-width: $min-width + px) and (orientation: landscape) { @content; }
}
@mixin max-h-ls($max-height){
  @media screen and (max-device-height: $max-height + px) and (orientation: landscape) { @content; }
}
@mixin min-h-ls($min-height){
  @media screen and (min-device-height: $min-height + px) and (orientation: landscape) { @content; }
}
