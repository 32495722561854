.profile{
  &-email{
    input{
      font-size: 15px;
      width: 280px;
    }
  }
  &-content{
    padding-left: 30px;
    h5{
      margin-bottom: 20px;
      a{
        font-size: 12px;
      }
    }
    ul{
      list-style: none;
    }
    li{
      margin-bottom: 10px;
    }
    .title{
      &-pin{
        padding-bottom: 5px;
        padding-right: 5px;
        display: inline-block;
      }
      &-device{
        padding-bottom: 5px;
        padding-left: 30px;
        display: inline-block;
      }
    }
    .block{
      &-pin{
        display: flex;
      }
      &-device{
        margin-bottom: 20px;
        ul{
          padding-left: 30px;
        }
        li{
          margin-bottom: 5px;
        }
      }
    }
  }
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: .25rem;
  margin: 10px;
  box-shadow: 1px 1px 6px 0 rgba(218, 218, 253, 65%), -1px -1px 6px 0 rgba(206, 206, 238, 54%);
  .card-body{
    padding: 0 10px;
  }
  .row{
    padding: 15px 0;
    border-bottom: 1px solid rgba(#adb5bd, .4);
    min-height: 65px;
    &:last-child{
      border: none;
    }
  }
  .block-validate{
    position: relative;
    z-index: 0;
    span{
      position: absolute;
      top: 26px;
      left: 3px;
      white-space: nowrap;
      font-size: 11px;
    }
    .form-check{
      span{
        top: 12px;
      }
    }
  }
}
.bg-pin{
  background-color: #e9ecefb3;
}
.bg-device{
  background-color: #ffffff;
  border-bottom: 1px solid rgba(#adb5bd, .4);
}

