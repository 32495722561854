@import "../fonts";
@import "../variables";
@import "../mixins";

.modal-custom.modal{

  @include max-w($media-xs){
    background-color: rgb(72, 70, 76, .32);
  }
  &.full-height{
    .modal-dialog{
      @include min-w($media-xs + 1){
        top: 10px;
        transform: translateY(0);
      }
      @include max-w-ls($media-md){
        margin-top: 220px;
        top: 0;
        transform: translateY(0);
      }
    }
  }
  .modal-dialog{
    padding: 8px 0;
    border-radius: 28px;
    background-color: #fdfdfd;
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden;
    @include min-w($media-xs + 1){
      top: 50%;
      transform: translateY(-50%);
    }
    @include max-w-ls($media-md){
      margin-top: 220px;
      margin-bottom: 0;
      padding-top: 20px;
      min-height: calc(100vh - 198px);
      top: 0;
      transform: translateY(0);
    }
    @include max-w($media-xs){
      margin-top: 198px;
      margin-bottom: 0;
      padding-top: 20px;
      min-height: calc(100vh - 198px);
    }
  }
  .modal-body{
    padding: 15px 24px 0 24px;
    @include max-w($media-xs){
      padding: 14px 15px 0 20px;
    }
  }
  .modal-content{
    h5, h3, h4{
      font-family: $font-r-b;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.25px;
      text-align: left;
      color: #49454f;
      margin-bottom: 0;
    }
    h4{
      max-width: 100%;
    }
    p, li{
      font-family: $font-r-r;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.25px;
      text-align: left;
      color: #49454f;
      margin-bottom: 19px;
    }
    li{
      margin-bottom: 10px;
    }
    .number-headings{
      counter-reset: h3counter;
      h3:before {
        counter-increment: h3counter;
        content: counter(h3counter) ". ";
      }
    }
  }
  .modal-footer-custom{
    padding: 30px 32px 10px 32px;
    @include max-w($media-xs){
      padding: 20px 20px;
    }
  }
  .modal-support{
    padding-top: 3px;
    @include max-w($media-xs){
      padding-top: 8px;
    }
    &-small{
      max-width: 600px;
    }
    a {
      color: #49454f;
      &[href^=tel]{
        text-decoration: none;
      }
      &:focus{
        outline: 1px #605d64 dashed;
      }
    }
    b{
      white-space: nowrap;
    }
  }
  h2{
    font-family: $font-rs-b;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #36343b;
    margin-bottom: 14px;
    @include max-w($media-xs){
      margin-bottom: 0;
    }
    &#dialogLabelmodalFAQ{
      @include max-w($media-xs){
        margin-bottom: 14px;
      }
    }
  }
  .close{
    font-family: $font-r-m;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: 0.1px;
    text-align: center;
    color: #a32235;
    text-decoration: none;
    &:focus{
      outline: 1px #605d64 dashed;
    }
  }
}
.modal-backdrop.show{
  @include max-w-ls($media-md){
    opacity: .32;
  }
  @include max-w($media-xs){
    opacity: .32;
  }
}
.modal-small{
  .modal-dialog{
    max-width: 664px!important;
  }
}

.modal-comment{
  textarea.form-control{
    width: 100%;
    max-width: 100%;
  }
  .alert{
    margin-bottom: 0;
  }
}

[data-blocked="blocked-modal"]{
  pointer-events: none;
  button, a{
    pointer-events: none;
  }
}
