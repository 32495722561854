.filter-date {
  max-width: 460px;
  display: flex;
  .input-date{
    cursor: pointer;
    display: flex;
    align-items: center;
    .flatpickr{
      max-width: 135px;
      display: flex;
      align-items: center;
    }
  }
  i{
    font-size: 20px;
  }
}
.dataTables_length{
  display: flex;
}
