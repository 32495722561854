@import "../variables";
@import "../mixins";
$margin-top:  50vh;
.message-page{
  position: relative;
  top: $margin-top;
  transform: translateY(-50%);
  .title-label{
    position: static;
  }
  &.message-added-ornament{
    top: 42vh;
    @include min-w($media-xs + 1){
      top: 12px;
      transform: translateY(0);
    }
    h2{
      @include min-w($media-xs + 1){
        padding-top: 6px;
      }
    }
  }
  &.message-reset-password{
    top: 42vh;
    @include min-w($media-xs + 1){
      top: -6px;
      transform: translateY(0);
    }
    @include max-w($media-xs){
      top: 232px;
    }
    h2{
      @include min-w($media-xs + 1){
        padding-top: 6px;
      }
      img{

        @include min-w($media-xs + 1){
          top: 7px;
          right: 0;
        }
        @include min-w(565){
          top: 30px;
          right: -24px;
        }
        @include max-w($media-xs){
          right: 28px;
          top: 7px;
        }
      }
    }
  }
}
.icon-top{
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  max-width: 510px;
  margin: 0 auto;
  img{
    position: absolute;
    top: 16px;
    right: 30px;
    @include max-w-ls($media-md){
      right: -27px;
      top: 5px;
    }
  }
}
.icon-outside-right{
  img{
    @include min-w(530){
      right: -10px;
    }
    @include max-min-w(529, $media-xs + 1){
      right: 0;
      top: -3px;
    }
    @include max-w-ls($media-md){
      right: -27px;
      top: 5px;
    }
  }
}
