.table-log{
  margin-top: 13px!important;
  height:432px;
  overflow-y:auto;
  &.row{
    padding-top: 0;
    table{
      padding: 0!important;
    }
  }
  table{
    width: 100%;
    &.table-bordered.dataTable{
      margin-top: 0!important;
      border-collapse: separate!important;
    }
  }
  tr{
    vertical-align: top;
  }

  thead{
    vertical-align: middle;
    position: sticky!important;
    top: 0;
    background-color: white;
    th{
      &:after{
        left: calc(100% - 20px);
      }
    }
  }
}
.table-action-panel{
  padding-top: 0!important;
}
