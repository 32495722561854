@import "../variables";
@import "../mixins";
$height-banner: 232px;
$height-banner-mobile: 206px;
.banner{
  height: $height-banner;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  @include max-w($media-xs){
    height: $height-banner-mobile;
  }
  &-img{
    width: 100%;
    height: $height-banner;
    object-fit: cover;
    object-position: top;
    filter: blur(2px);
    @include max-w($media-xs){
      display: none;
    }
    &-mobile{
      width: 100%;
      object-fit: cover;
      object-position: center;
      filter: blur(2px);
      height: $height-banner-mobile;
      @include min-w($media-xs + 1){
        display: none;
      }
    }
  }
  &:after{
    content: "";
    background: url("/assets/images/bottom-line.png") left/contain;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    height: 6px;
    width: 100%;
  }
  .logo{
    position: absolute;
    left: 0;
    right: 0;
    bottom: -31px;
    z-index: 1;
    margin: 0 auto;
    max-width: 488px;
    width: 100%;
    @include max-w($media-xs){
      left: 24px;
      bottom: -21px;
      //max-width: 366px;
      max-width: calc(100vw - 25px);
    }
    a:focus{
      outline: 1px #605d64 dashed;
    }
    img{
      max-width: 100%;
    }
  }
}
.container-form{
  max-width: 510px;
  padding: 64px 30px;
  margin: 0 auto;
  @include max-w($media-xs){
    padding: 43px 20px;
  }
}
