@import "../fonts";

.btn{
  &:not(.btn-success):not(.btn-danger):not([data-bs-dismiss]):not(.btn-primary):not(.default){
    height: 56px;
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 5px;
    font-family: $font-r-m;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: 0.1px;
    text-align: center;
    color: #fff;
  }
  &-red{
    background-color: #a32235;
  }
  &-green{
    background-color: #006548;
  }
  &-default{
    background-color: rgba(29, 27, 32, 0.12);
    color: rgba(#1d1b20, .38)!important;
  }
}
