@import "../mixins";
.verify-age{
  .title-label img{
    @include max-min-w(433, 391){
      right: -24px;
      top: -20px;
    }
  }
  .icon-select{
    position: absolute;
    right: 18px;
    top: 18px;
  }
  .input-text{
    *{
      cursor: pointer;
    }
  }
  .dropdown-menu{
    width: 100%;
    left: -13px!important;
    top: 7px!important;
    height: 300px;
    overflow: auto;
    li{
      a{
        font-size: 14px;
      }
    }
  }
  &>span:last-of-type{
    margin-top: 12px;
    display: inline-block;
  }
  form{
    padding-top: 28px;
    .btn{
      margin-top: 29px;
    }
    .input-text .invalid-feedback{
      text-align: left;
      transform: translate(3px, 5px);
    }

  }

}
