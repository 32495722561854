@import "../fonts";
@import "../variables";
@import "../mixins";
.input-ornament{
  border-top: solid 1px #ded8e1;
  &:first-child{
    border: none;
  }
  &+.input-ornament{
    padding-top: 23px;
    @include max-w($media-xs){
      padding-top: 32px;
    }
  }
  h5, .label-input-ornament{
    font-family: $font-r-b;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000;
    margin-bottom: 1px;
    position: relative;
    z-index: 0;
    @include max-w(365){
      padding-right: 20px;
    }
    a{
      position: absolute;
      right: 5px;
      top: -1px;
      z-index: 0;
      &:focus{
        outline: 1px #49454f dashed;
      }
    }
  }
  p{
    font-family: $font-r-r;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: left;
    color: #49454f;
    //max-width: 350px;
  }
  .input-text{
    margin-bottom: 24px;
  }
}
