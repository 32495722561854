@font-face {
    font-family: "RobotoSlab-Regular";
    src: local("RobotoSlab-Regular"),
    url("../fonts/RobotoSlab-Regular.ttf") format("truetype");
    font-weight: normal;
}
@font-face {
    font-family: "RobotoSlab-Bold";
    src: local("RobotoSlab-Bold"),
    url("../fonts/RobotoSlab-Bold.ttf") format("truetype");
    font-weight: normal;
}
@font-face {
    font-family: "Roboto-Medium";
    src: local("Roboto-Medium"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: normal;
}
@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
}
@font-face {
    font-family: "Roboto-Bold";
    src: local("Roboto-Bold"),
    url("../fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: normal;
}

$font-rs-r: "RobotoSlab-Regular", sans-serif;
$font-rs-b: "RobotoSlab-Bold", sans-serif;
$font-r-m: "Roboto-Medium", sans-serif;
$font-r-r: "Roboto-Regular", sans-serif;
$font-r-b: "Roboto-Bold", sans-serif;
