@import "../variables";
@import "../mixins";
@import "../fonts";

.maximum-devices {
  padding-top: 20px;

  .panel-top {
    display: flex;
    justify-content: space-between;

    img {
      margin-right: 6px;
      @include max-w($media-xs) {
        margin-right: 0;
      }
    }
  }

  .txt-red {
    color: #A32235;
  }

  .show-mobile {
    @include min-w($media-xs + 1) {
      display: none;
    }
  }

  .hide-mobile {
    @include max-w($media-xs) {
      display: none;
    }
  }

  .icon-warning {
    line-height: 59px;

    i {
      @include max-w($media-xs) {
        font-size: 33px;
      }
    }
  }

  .link-info {
    margin-bottom: 0;

    a {
      font-size: 16px;
      margin-top: 8px;
      display: inline-block;

    }
  }

  .message-page {
    top: 67px;
    transform: translateY(0);
    @include min-w($media-xs + 1) {
      top: 18px;
    }

    h2 {
      padding-top: 19px;
    }
  }
}

.input-checkbox {
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  margin-bottom: 15px;

  .form-check{
    *{
      cursor: pointer;
    }
    &.input-disabled{
      pointer-events: none;
      opacity: .5;
    }
    label{
      margin: 0;
      font-family: $font-r-r;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: left;
      color: #49454f;
    }
    &-input{
      &:checked {
        background-color: #006548;
        border-color: #006548;
      }
      &:focus {
        border-color: #006548;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(#006548,.25);
      }
    }
  }
}
.forget-device{
  h2{
    img{
      @include max-w($media-xs){
        display: none;
      }
    }
  }
  form{
    padding-top: 28px;
  }
  h5{
    font-family: $font-r-m;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: left;
    color: #36343b;
    margin-bottom: 0;
  }
  h6{
    font-family: $font-r-r;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: left;
    color: #36343b;
    text-transform: capitalize;
  }
  .input-checkbox{
    padding-top: 8px;
    border-top: 1px solid #ded8e1;
    margin-bottom: 0;
    @include max-w($media-xs){
      padding-top: 16px;
    }
    &:first-of-type{
      border: none;
    }

  }
  .form-check{
    padding: 8px 0 14px 43px;
  }
  .btn{
    margin-top: 10px;
  }
}
.message-forget-device{
  top: 0;
  transform: translateY(0);
  h2 img{
    @include max-w($media-xs){
      display: none;
    }
  }

}
