@import "../fonts";
@import "../variables";
@import "../mixins";
.pin-form{
  form{
    padding: 24px 0;
    @include max-w($media-xs){
      padding-top: 20px;
    }
  }
  .title-label img{
    @include max-w($media-xs){
      display: none;
    }
  }
  .input-text{
    @include max-w($media-xs){
      &:last-child{
        margin-bottom: 31px;
      }
    }
  }
  .form-info{
    font-family: $font-r-r;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    text-align: left;
    color: #006548;
    svg{
      margin-right: 12px;
      transform: translate(2px, -2px);
    }
  }
  .block-info{
    text-align: left;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.36;
    padding-bottom: 17px;
  }
  .error-info{
    font-family: $font-r-r;
    font-size: 14px;
    font-weight: normal;
    line-height: 2;
    letter-spacing: 0.25px;
    text-align: left;
    color: red;
  }
  .block-add-component{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span{
      font-family: $font-r-r;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.43;
      letter-spacing: 0.25px;
      text-align: left;
      color: #36343b;
    }
    a{
      font-family: $font-r-m;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 0.1px;
      text-align: left;
      color: #006548;
      text-decoration: none;
      padding-top: 14px;
      margin-left: 15px;
      &:focus{
        outline: 1px #006548 dashed;
      }
      svg{
        margin-right: 12px;
        transform: translate(0, -2px);
      }
    }
  }
}
