@import "../fonts";
@import "../variables";
@import "../mixins";
.registration-form {
  max-width: 510px;
  padding: 28px 30px;
  margin: 0 auto;

  h2, h1.title-label {
    font-family: $font-rs-b;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-bottom: 12px;
  }

  span {
    font-family: $font-r-r;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: left;
    color: #49454f;
  }

  form {
    padding: 36px 0;
  }

  .input-text {
    height: 56px;
    margin-bottom: 16px;
    padding: 8px 13px;
    border-radius: 8px;
    border: solid 1px #dadada;
    background-color: #fff;
    position: relative;
    z-index: 0;

    input {
      width: 100%;
      height: 100%;
      border: none;
      font-family: $font-r-r;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: left;
      color: #49454f;
    }

    label {
      position: absolute;
      top: -9px;
      left: 13px;
      z-index: 0;
      padding: 2px;
      font-family: $font-r-r;
      font-size: 12px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #49454f;
      background-color: #dadada;
    }

    .invalid-feedback, .valid-feedback {
      text-align: right;
      margin-top: 7px;
    }
    .is-invalid{
      &+label{
        color: #bf4441;
      }
    }
    .is-valid{
      &+label{
        color: #49454f;
      }
    }
  }

  .input-checkbox {
    padding: 14px 0;
    .is-invalid + span{
      color: #bf4441;
      a{
        color: #bf4441;
      }
    }
    input {
      border-radius: 2px;
      border: solid 2px #49454f;
      //outline: solid 2px #49454f;
      font-family: $font-r-r;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.43;
      letter-spacing: 0.25px;
      text-align: left;
      color: #49454f;
      height: 19px;
      width: 19px;
      min-height: 19px;
      min-width: 19px;
      transform: translate(0px, 2px);
      margin-right: 11px;
      @include max-w($media-xs){
        width: 19px;
        height: 19px;
        min-width: 19px;
        min-height: 19px;
        margin-right: 16px;
      }
    }

    label, a, span {
      font-family: $font-r-r;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.43;
      letter-spacing: 0.25px;
      text-align: left;
      color: #49454f;
    }

    label {
      margin-left: 19px;
      margin-bottom: 22px;
      display: flex;
      gap: 7px;
    }
  }

  .box-invalid{
    border: 2px solid #B3261E;
    margin-bottom: 34px;
    &.mb-e{
      margin-bottom: 40px;
      @include max-w($media-xs){
        margin-bottom: 56px;
      }
    }
    label{
      color: #B3261E;
    }
    .invalid-feedback{
      color: #B3261E;
    }
  }

  .policy {
    padding: 29px 0 25px 0;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    @include max-w($media-xs){
      padding-top: 112px;
    }
    a {
      font-family: $font-r-b;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #605d64;
      margin-top: 54px;
    }
  }
}
.title-label{
  position: relative;
  z-index: 0;
  img{
    position: absolute;
    right: 5px;
    top: -2px;
    z-index: 0;
    @include max-w(390){
      right: -27px!important;
      top: -27px;
    }
  }
}
.grecaptcha-badge{
  opacity: 0;
  pointer-events: none;
}
#inline-badge{
  position: relative;
  z-index: 0;
  overflow: hidden;
  height: 80px;
  width: calc(100% - 120px);
  min-width: 260px;
  .grecaptcha-badge{
    opacity: 1;
    pointer-events: auto;
    position: absolute!important;
    &:hover{
      right: 0!important;
    }
  }
  &.inline-badge{
    margin-left: auto;
    margin-bottom: 14px;
    margin-top: 9px;
    @include max-w($media-xs){
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}

