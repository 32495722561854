.admin-settings{
  .card-body{
    padding: 0 15px;
  }
  &.card .row{
    border: none;
    .block-validate span{
      left: -7px;
    }
  }
}
