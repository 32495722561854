.form-check{
  &-label, &-input{
    cursor: pointer;
  }
}
.box-password{
  &-show{
    display: block;
  }
  &-hide{
    display: none;
  }
}
