@import "../fonts";
$red: #B3261E;
.error-page{
  h1{
    color: $red;
    text-align: center;
    font-size: 48px;
    font-family: $font-r-b;
  }
  h2{
    color: $red;
    text-align: center;
    font-size: 32px;
    margin-bottom: 30px;
    font-family: $font-r-r;
  }
  .block-info{
    padding-top: 0;
  }
  .splitter{
    margin: 20px 0 15px;
  }
}
